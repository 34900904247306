/* src/index.css */
@font-face {
    font-family: 'Glacial Indifference';
    src: url('../source/font/GlacialIndifference-Regular.otf') format('otf'),
         url('../source/font/GlacialIndifference-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Glacial Indifference';
    src: url('../source/font/GlacialIndifference-Bold.otf') format('otf'),
         url('../source/font/GlacialIndifference-Bold.otf') format('otf');
    font-weight: bold;
    font-style: normal;
}

:root {
    --bg-color: #f0f8ff;
    --text-color: #4a1c40;
    --border-color: #b088f9;
    --bg-button-color: #025299;
    --text-button-color: #fff;
    --bg-color-navbar: #02529980;
}

body {
    margin: 0;
    padding: 0;

    background-color: var(--bg-color);
    color: var(--text-color);
    border-color: var(--border-color);

    font-family: 'Glacial Indifference', sans-serif;
}
