/********** Global Styles **********/
.container {
    /* background-color: red; */

    display: flex;
    flex-direction: column;
    gap: 1rem;

    width: 100%;
}

.label {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: var(--bg-button-color);

    justify-content: space-between;

    min-width: 380px;
}


.input {
    padding: 0.3rem;
    border-radius: 4px;
    border: 1px solid;
    text-align: center;

    min-width: 180px;
}

.select {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid;
    margin-left: 10px;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;

    gap: 1rem;
}

button {
    background-color: var(--bg-button-color);
    color: var(--text-button-color);
    border: none;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    border: 1px solid transparent;
    transition: background-color 0.3s, color 0.3s;
}

button:hover {
    background-color: var(--bg-color);
    color: var(--text-color);
    border: 1px solid;
}

button:disabled {
    background-color: var(--bg-color-disabled, #ccc);
    color: var(--text-color-disabled, #666);
    border-color: var(--border-color-disabled, #999);
    cursor: not-allowed;
}

.elevated {
    box-shadow: 0 5px 4px -2px rgba(255, 255, 255, 0.6);
    border-radius: 4px;
}

/********** END Global Styles **********/

/********** App **********/
.container_app {
    display: grid;
    grid-template-areas:
        "header header"
        "navbar main"
        "navbar footer"
    ;

    grid-template-rows:
        auto
        /*header header*/
        1fr
        /*navbar main*/
        auto
        /*navbar footer*/
    ;

    grid-template-columns:
        50px auto
        /*header*/
        /*header*/
        /*navbar*/
        /*main*/
        /*navbar*/
        /*footer*/
    ;

    height: 100vh;
}

.header_app {
    grid-area: header;
}

.navbar_app {
    grid-area: navbar;
}

.main_app {
    grid-area: main;
    padding-top: 6rem;
    padding-left: 3rem;
    padding-right: 3rem;
    box-sizing: border-box;

    overflow: auto;
}


/********** END App **********/

/********** Footer **********/
.footer_app {
    grid-area: footer;

    display: flex;
    align-items: flex-end;
    justify-content: center;

}

.footer_img {
    height: 30px;
    margin-right: 40px;
    margin-top: 5px;
    margin-bottom: 20px;
}

/********** END Footer **********/

/********** Header **********/
.container_header {
    display: flex;
    align-items: center;
    gap: 1rem;

    max-height: 50px;

    position: relative;
}

.img_header {
    max-width: 50px;
    max-height: 50px;
}

.h1_header {
    color: var(--bg-button-color);
    font-size: medium;
}

.hr_header {
    height: 4px;
    background-color: var(--bg-button-color);
    border: 0;
    margin: 0;
}

/********** END Header **********/

/********** NavBar **********/
.img_navbar {
    width: 50px;
    height: 50px;

}

.ul_navbar {
    background-color: var(--bg-button-color);
    list-style: none;

    margin: 0;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-top: 30px;
    height: calc(100% - 40px);
    ;

    display: flex;
    flex-direction: column;

    gap: 10px;

    align-items: center;
    justify-content: flex-star;

    border-radius: 0 30px 30px 0;
}

.info_button {
    margin-top: auto;
    /* Move o botão Info para o final da barra */
}

.li_navbar {
    display: flex;
}

.button_navbar {
    border: none;
    background-color: transparent;
    padding: 0;
}

.button_navbar:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.5); 
}

/********** END NavBar **********/

/********** Barra de Ferramentas **********/
.ferbar {
    background-color: var(--bg-button-color);
    display: flex;
    justify-content: space-evenly; 
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 1px;
    padding-bottom: 1px;
    gap: 8px;
    border-radius: 20px;
}

.button_ferbar {
    background-color: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    
}

.button_ferbar:hover{
    border: none;
    background-color: rgba(255, 255, 255, 0.5); 
}

.img_ferbar {
    width: 32px;
    height: 32px;

}



/********** END Barra de Ferramentas **********/


/********** darkModeButton **********/
.button-dark-mode-button {
    position: absolute;
    right: 3rem;
    top: 70px;
}

/********** END darkModeButton **********/

/********** StationInformation **********/
.name {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid;
    margin-left: 10px;

    width: 70%;

    text-align: center;
}

/********** END StationInformation **********/

/********** Graphic **********/

.menu_graphic {
    margin-top: -4rem;
}

.header_graphic {
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.subheader_graphic {
    display: flex;
    flex-direction: column;
    min-width: 140px;
    gap: 10px;
}

/********** END Graphic **********/

/********** Modal **********/
.div_modal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.div_button_modal {
    display: flex;
    justify-content: space-between;
}

.button_modal {}

/********** END Modal **********/

/********** Colunmdefinition **********/
.sub_container_colunmdefinition {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.sub_label_colunmdefinition {
    display: flex;
    gap: 1rem;
}

.mounted {
    overflow: auto;
    max-height: 400px;
    border: 1px solid black;
    padding: 1px;
}

/********** END Colunmdefinition **********/

.tooltip {
    background-color: white;
    /* Azul */
    color: var(--bg-button-color);
    /* Texto branco */
    font-size: 14px;
    /* Tamanho da fonte opcional */
    padding: 3px;
    /* Espaçamento interno opcional */
    border-radius: 4px;
    /* Bordas arredondadas */
}

/*********** Botão tipo interuptor "Toggle Switch" ***********/
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 17px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked+.slider:before {
    transform: translateX(13px);
}

/*********** END Toggle Switch ***********/

/*********** Label do botão tipo interuptor "Toggle Switch" ***********/
/* Estilo para a label do ToggleSwitch */
.label_estilo_toggleSwitch {
    font-size: 13px;
    font-weight: bold;
    margin-right: 10px;
    /* Espaço entre a label e o toggle */
}

/*********** END Label Toggle Switch ***********/

/*********** Estilos Aba de Informações do Projeto ***********/
.h1_InfoProject {
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: space-between;
    min-width: 380px;
    font-size: 32px;
    color: var(--bg-button-color);
    /* Tom de azul definido na variável */
    border-bottom: 2px solid var(--bg-button-color);
    /* Linha decorativa */
    padding-bottom: 0.5rem;
    /* Espaçamento entre o texto e a linha */
}

.p_InfoProject {
    display: flex;
    /* Alinha os itens em uma linha */
    align-items: center;
    /* Centraliza verticalmente a imagem e o texto */
    justify-content: flex-start;
    /* Garante que tudo fique alinhado à esquerda */
    gap: 1rem;
    /* Adiciona espaço entre a imagem e o texto */
    font-weight: normal;
    /* Texto sem negrito */
    min-width: 380px;
    font-size: 17px;
    color: var(--bg-button-color);
    /* Tom de azul definido na variável */
    text-align: justify;
    /* Justifica o texto */
}

.profileImage {
    width: 150px;
    height: auto;
    margin: 0;
    /* Remove margens verticais para alinhar perfeitamente */
    border: 2px solid var(--bg-button-color);
    /* Adiciona uma borda */
    background-color: #f9f9f9;
    /* Fundo cinza claro */
}

.teamContainer {
    display: flex;
    /* Exibe os membros da equipe lado a lado */
    justify-content: center;
    /* Centraliza horizontalmente */
    gap: 2rem;
    /* Espaço entre os membros */
    flex-wrap: wrap;
    /* Permite que os membros quebrem linha se necessário */
    margin-top: 2rem;
    /* Espaço acima da seção */
}

.member {
    display: flex;
    align-items: center;
    /* Alinha imagem e texto verticalmente */
    gap: 1rem;
    /* Espaço entre a imagem e o texto */
    text-align: left;
    /* Alinha o texto à esquerda */
}

.nameText {
    color: var(--bg-button-color);
    /* Azul definido na variável */
    font-size: 17px;
    /* Ajuste de tamanho, opcional */
    font-weight: bold;
    /* Negrito para destaque */
}

.roleText {
    color: var(--bg-button-color);
    /* Azul definido na variável */
    font-size: 15px;
    /* Ajuste de tamanho menor para funções */
    font-weight: normal;
    /* Sem negrito */
}

.link {
    text-decoration: none;
    /* Remove qualquer sublinhado */
    color: #0077ff;
    /* Azul vibrante */
    font-weight: 500;
    /* Peso médio da fonte */
    transition: color 0.3s ease;
    /* Transição suave */
}

.link:hover {
    color: #0056b3;
    /* Azul mais escuro no hover */
}

.ul_link {
    list-style: none;
    /* Remove os marcadores padrão */
    padding-left: 0;
    /* Remove o recuo padrão */
}

.li_link {
    margin-bottom: 0.5rem;
    /* Espaçamento entre os itens */
    position: relative;
    /* Mantém o posicionamento, caso necessário */
}

/*********** END Informações do Projeto ***********/

/*********** Modal Estatíticas ***********/
.modalContent {

    width: 25%;
    /* Largura do modal */
    height: 80%;
    /* Altura do modal */
    margin: auto;
    /* Centraliza horizontal e verticalmente */
    border-radius: 10px;
    /* Borda arredondada */
    padding: 20px;
    /* Espaçamento interno */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Sombra */
    background-color: #fff;
    /* Fundo branco */
    overflow: auto;
    /* Para conteúdo longo */
}

.h2_Statistics {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #025399;
    font-size: 24px;
    justify-content: center;

}

.h3_Statistics {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #f0f8ff;
    font-size: 18px;
    background-color: #025299;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
}


.li {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #025399;
    justify-content: flex-start;
}

.span {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #025399d2;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;

}

.div_button_modal_statistic {
    display: flex;
    justify-content: right;
}

.ul_statistic {
    list-style-type: none;
    padding: 0;
}

/*********** END Modal Estatíticas ***********/

/*********** Modal Cálculos ***********/

.h3_calculos {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #f0f8ff;
    font-size: 16px;
    background-color: #025299;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    width: 220px;
    /* Tamanho fixo */
    text-align: center;
    /* Centraliza o texto dentro do fundo */

}

/*********** END Modal Cálculos ***********/

/*********** Modal Mapa ***********/

.h2_mapa {
    display: flex;
    font-weight: bold;
    align-items: center;
    color: #f0f8ff;
    font-size: 16px;
    background-color: #025299;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    width: 220px;
    text-align: center;
    margin-bottom: 5px;
    /* Reduz a margem inferior do título */
}

.station_map_container {
    margin-top: 20px;
    height: 220px;
    width: 100%;
}

.map_container {
    height: 200px;
    width: 80%;
    margin: 0 auto;
}
/*********** END Modal Mapa ***********/